<template>
    <div class="simple-view h-full">
        <div class="flex h-full flex-1 flex-col">
            <main class="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
                <div class="flex-1 overflow-hidden relative">
                    <div class="h-full dark:bg-gray-800 relative">
                        <main-content :conversation="conversation" :fetchStatus="fetchStatus"></main-content>
                    </div>
                </div>
                <div
                    class="absolute bottom-0 left-0 right-10 w-full dark:border-transparent bg-vert-light-gradient dark:bg-vert-dark-gradient input-area">
                    <send-form @resetResponse="resetResponse" @stopGeneration="pauseSearch" :conversation="conversation"
                        ref="sendFormEle" @handleGetData="handleGetData" :fetchStatus="fetchStatus"></send-form>
<!--                    <div-->
<!--                        class="px-3 pt-2 pb-3 text-center text-xs text-black/50 dark:text-white/50 md:px-4 md:pt-3 md:pb-6">-->
<!--                        本站点仅供学习使用，使用前请知晓 <a href="./disclaimer_ai_edu.html" target="_blank" rel="noreferrer"-->
<!--                            style="text-decoration-line:underline">免责申明</a> </div>-->
                </div>
            </main>
        </div>
    </div>
</template>


<script setup>
import { fetchEventSource } from '@microsoft/fetch-event-source';

import MainContent from '@/components/mainContent.vue'
import sendForm from '@/components/sendForm.vue';
import { ref, defineComponent, reactive } from 'vue'
import {openaiUrl, key, defaultToken} from '@/config/config';
defineComponent({
    MainContent,
    sendForm
})
let fetchStatus = ref(false);
let conversation = ref([]);
let isClose = ref(false);
const sendFormEle = ref(null);
const handleGetData = (searchVal) => {
    let value = searchVal.value
    if (fetchStatus.value) {
        return;
    }
    let item = reactive({ role: "user", content: value });
    conversation.value.push(reactive(item));
    getData(item);

}
const getParamsByUrl = (name) => {
    try{
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[""])[1].replace(/\+/g,'%20'))||null;

    }catch (e) {
        return null;
    }

}
const resetResponse = () => {
    if (conversation.value.length == 0) {
        return
      }

      let item = conversation.value.pop();
      if (item.role == 'user') {
        delete item.type;
        conversation.value.push(item);
        getData(item);
        return
      } else {
        resetResponse();
      }
}
const pauseSearch = () => {
    const pauseSearch = () => {
        isClose.value = true;
    }
}

const removeErrorItem =  () =>{
    conversation.value = conversation.value.filter(item => item.type !== 'error' || item.type !== '1' )
}


/* 请求接口 */
const getData = (sendItem) => {


    const ctrl = new AbortController();
    // 获取最后一条数据


    let item = reactive({ role: "assistant", content: '' });
    debugger
    conversation.value.push(item);
    isClose.value = false;
    fetchStatus.value = true;
    sendFormEle.value.resetSearchVal();
    let eventSource = fetchEventSource(openaiUrl, {
        method: 'POST',
        headers: {
            "Authorization": getParamsByUrl("token")||defaultToken,
            'Tenant-Identifier':'chitchat'
        },
        body: JSON.stringify({
            "model": "gpt-3.5-turbo",
            "stream": true,
            "messages": [sendItem]
        }),

        signal: ctrl.signal,
        onmessage(event) {
            console.info(event.data);
            if (event.data == '[DONE]') {
                closeFetch(ctrl)
                return
            }

            if (isClose.value) {
                closeFetch(ctrl)
                return
            }

            let data = JSON.parse(event.data);
            // let currentMsg = data.choices[0]['delta']['content'];
            // if (currentMsg !== undefined) {
            //     item.content = item.content + currentMsg;
            // }

            if(data.error){
                item.type = 'error';
                item.content =decodeURIComponent(atob(data.error));
                return
            }
            // let currentMsg = data.choices[0]['delta']['content'];
            let currentMsg = decodeURIComponent(atob(data.content));
            if (currentMsg !== undefined) {
                item.content = item.content + currentMsg;
            }



        },
        onerror() {
            console.info("onerror");
            closeFetch(ctrl)
            item.type = 'error';
            sendItem.type='1'
            throw "onerror";
        }
    })


    const closeFetch = (ctrl) => {
        fetchStatus.value = false;
        console.info("关闭了----------------");
        ctrl.abort();
    }
}


</script>
